<template>
  <div class="app-container">
    <CrudTable ref="table" entity="ActionStatus" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';

import CrudTable from '@/components/crud/CrudTable';

export default {
  components: {
    CrudTable
  },

  mixins: [crudIndexMixin],

  data() {
    return {
      columns: ['name']
    };
  }
};
</script>

<style scoped></style>
