export function getDefaultSummary({ id, name, code, description, reference }) {
  if (name === undefined) {
    let r = `${code || id}`;
    if (description) {
      r += ` (${description})`;
    }
    return r;
  }
  return `${name} (${reference || code || description})`;
}
/**
 * Assign dynamic props to all fields in the given Editor definition
 * @param {Object} definition The editor definition
 * @param {function} dynamicProps New dynamicProps to be assigned to each field
 * @param {string[]} skip Field names to skip
 */
export function injectDynamicProps(definition, dynamicProps, skip = []) {
  const inject = (page, dynamicProps, skip) => {
    Object.keys(page).forEach(fieldName => {
      if (!skip.includes(fieldName)) {
        if (!page[fieldName].dynamicProps) {
          page[fieldName].dynamicProps = dynamicProps;
        }
      }
    });
  };
  if (definition.groups) {
    definition.groups.forEach(tab => {
      inject(tab, dynamicProps, skip);
    });
  } else {
    inject(definition, dynamicProps, skip);
  }
}

export function convertSearchToQueryParams(search) {
  const queryParams = new URLSearchParams();

  // -----> key  ,  value
  // e.g. "name", "Jansen"
  for (const key in search) {
    if (Object.prototype.hasOwnProperty.call(search, key)) {
      queryParams.append(key, search[key]);
    }
  }

  return queryParams.toString();
}
