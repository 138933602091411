<template>
  <el-tooltip :disabled="!iconOnly" class="item" effect="dark" :content="caption" placement="bottom-start">
    <el-button
      :disabled="disabled"
      :loading="loading"
      :type="type"
      :icon="icon"
      :size="size"
      :class="iconOnly ? 'icon-only' : ''"
      class="base-button button"
      v-bind="$attrs"
      @click="handleClick"
    >
      <span v-if="iconOnly && svgIcon" class="svg-container">
        <svg-icon :icon-class="svgIcon" />
      </span>
      <span v-if="!iconOnly" class="button-text">{{ caption }}</span>
    </el-button>
  </el-tooltip>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  components: {
    SvgIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    svgIcon: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    size: String
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.button:not(:first-child) {
  margin-left: 10px;
}
.sub-navbar button {
  &.icon-only {
    font-size: 19px;
  }
  .svg-container {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .sub-navbar button {
    .svg-container {
      margin-left: 0px;
    }
  }
}
</style>
